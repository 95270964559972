import * as i0 from '@angular/core';
import { Injectable, isDevMode, ENVIRONMENT_INITIALIZER, NgModule } from '@angular/core';
import * as i1 from '@ngxs/store';
import { ofActionSuccessful, InitState, UpdateState, getStoreMetadata, getValue, setValue, getActionTypeFromInstance, NGXS_PLUGINS } from '@ngxs/store';
import { take } from 'rxjs/operators';
function noop() {
  return () => {};
}
class ResetService {}
/** @nocollapse */
ResetService.ɵfac = function ResetService_Factory(t) {
  return new (t || ResetService)();
};
/** @nocollapse */
ResetService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ResetService,
  factory: ResetService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResetService, [{
    type: Injectable
  }], null, null);
})();
class ResetHandler {
  constructor(actions$, store, resetService) {
    this.actions$ = actions$;
    this.store = store;
    this.resetService = resetService;
    this.actions$.pipe(ofActionSuccessful(InitState), take(1)).subscribe(() => this.resetService.initialState = this.store.snapshot());
    this.actions$.pipe(ofActionSuccessful(UpdateState)).subscribe(({
      addedStates
    }) => this.resetService.initialState = {
      ...this.resetService.initialState,
      ...addedStates
    });
  }
}
/** @nocollapse */
ResetHandler.ɵfac = function ResetHandler_Factory(t) {
  return new (t || ResetHandler)(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i1.Store), i0.ɵɵinject(ResetService));
};
/** @nocollapse */
ResetHandler.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ResetHandler,
  factory: ResetHandler.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResetHandler, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.Actions
    }, {
      type: i1.Store
    }, {
      type: ResetService
    }];
  }, null);
})();

/**
 * Action to clear all state except given state(s)
 */
class StateClear {
  constructor(...statesToKeep) {
    const reducer = createMetaDataListReducer(isDevMode());
    this.statesToKeep = statesToKeep.reduce(reducer, []);
  }
}
StateClear.type = '@@CLEAR_STATE';
/**
 * Action to reset given state(s) to defaults
 */
class StateReset {
  constructor(...statesToReset) {
    const reducer = createMetaDataListReducer(isDevMode());
    this.statesToReset = statesToReset.reduce(reducer, []);
  }
}
StateReset.type = '@@RESET_STATE';
/**
 * Action to reset all states expect given state(s) to defaults
 */
class StateResetAll {
  constructor(...statesToKeep) {
    const reducer = createMetaDataListReducer(isDevMode());
    this.statesToKeep = statesToKeep.reduce(reducer, []);
  }
}
StateResetAll.type = '@@RESET_STATE_ALL';
/**
 * Action to overwrite state(s) with given value(s)
 */
class StateOverwrite {
  constructor(...overwriteConfigs) {
    const reducer = createMetaTupleReducer(isDevMode());
    const [states, values] = overwriteConfigs.reduce(reducer, [[], []]);
    this.statesToOverwrite = states;
    this.values = values;
  }
}
StateOverwrite.type = '@@OVERWRITE_STATE';
function getMetaData(state, devMode) {
  const meta = new Object(getStoreMetadata(state));
  const isNgxsMeta = meta.name && 'defaults' in meta;
  // Reusability Hack: devMode is number on purpose
  if (!isNgxsMeta && devMode === -2) {
    console.warn(`Reset Plugin Warning: ${meta.name} is not a state class.`);
    return null;
  }
  return meta;
}
function createMetaDataListReducer(devMode) {
  return (acc, state) => {
    // eslint-disable-next-line no-bitwise
    const meta = getMetaData(state, ~devMode);
    return meta ? acc.concat(meta) : acc;
  };
}
function createMetaTupleReducer(devMode) {
  return (acc, [state, value]) => {
    // eslint-disable-next-line no-bitwise
    const meta = getMetaData(state, ~devMode);
    return meta ? [acc[0].concat(meta), acc[1].concat(value)] : acc;
  };
}
class NgxsResetPlugin {
  constructor(resetService) {
    this.resetService = resetService;
  }
  clearStates(state, statesToKeep) {
    return statesToKeep.reduce((obj, meta) => {
      const path = getPath(meta);
      if (!path) {
        return obj;
      }
      const parts = path.split('.');
      const value = getValue(state, path);
      return parts.reduceRight((acc, part) => part in obj ? {
        [part]: {
          ...obj[part],
          ...acc
        }
      } : {
        [part]: acc
      }, value);
    }, {});
  }
  overwriteStates(state, statesToOverwrite, values) {
    statesToOverwrite.forEach((meta, index) => {
      const path = getPath(meta);
      if (!path) {
        return;
      }
      state = setValue(state, path, values[index]);
    });
    return state;
  }
  resetStates(state, statesToReset) {
    statesToReset.forEach(meta => {
      const path = getPath(meta);
      if (!path) {
        return;
      }
      state = setValue(state, path, typeof meta.defaults === 'undefined' ? {} : meta.defaults);
      if (meta.children) {
        state = this.resetStates(state, meta.children.map(getMetaData));
      }
    });
    return state;
  }
  resetStatesAll(state, statesToKeep) {
    const [metas, values] = statesToKeep.reduce((acc, meta) => {
      const path = getPath(meta);
      if (!path) {
        return acc;
      }
      acc[0].push(meta);
      acc[1].push(getValue(state, path));
      return acc;
    }, [[], []]);
    return this.overwriteStates(this.resetService.initialState, metas, values);
  }
  handle(state, action, next) {
    const type = getActionTypeFromInstance(action);
    switch (type) {
      case StateClear.type:
        state = this.clearStates(state, action.statesToKeep);
        break;
      case StateReset.type:
        state = this.resetStates(state, action.statesToReset);
        break;
      case StateResetAll.type:
        state = this.resetStatesAll(state, action.statesToKeep);
        break;
      case StateOverwrite.type:
        const {
          statesToOverwrite,
          values
        } = action;
        state = this.overwriteStates(state, statesToOverwrite, values);
        break;
      default:
        break;
    }
    return next(state, action);
  }
}
/** @nocollapse */
NgxsResetPlugin.ɵfac = function NgxsResetPlugin_Factory(t) {
  return new (t || NgxsResetPlugin)(i0.ɵɵinject(ResetService));
};
/** @nocollapse */
NgxsResetPlugin.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsResetPlugin,
  factory: NgxsResetPlugin.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsResetPlugin, [{
    type: Injectable
  }], function () {
    return [{
      type: ResetService
    }];
  }, null);
})();
function getPath(meta) {
  return meta.path;
}
class NgxsResetPluginModule {
  static forRoot() {
    return {
      ngModule: NgxsResetPluginModule,
      providers: [ResetService, ResetHandler, {
        provide: ENVIRONMENT_INITIALIZER,
        useFactory: noop,
        deps: [ResetHandler],
        multi: true
      }, {
        provide: NGXS_PLUGINS,
        useClass: NgxsResetPlugin,
        multi: true
      }]
    };
  }
}
/** @nocollapse */
NgxsResetPluginModule.ɵfac = function NgxsResetPluginModule_Factory(t) {
  return new (t || NgxsResetPluginModule)();
};
/** @nocollapse */
NgxsResetPluginModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsResetPluginModule
});
/** @nocollapse */
NgxsResetPluginModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsResetPluginModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NgxsResetPlugin, NgxsResetPluginModule, StateClear, StateOverwrite, StateReset, StateResetAll, getMetaData };
