import { Injectable } from "@angular/core";
import { State, Selector, Action, StateContext } from "@ngxs/store";
import { Api } from "app/services/api.service";
import { StateClear, StateResetAll } from "ngxs-reset-plugin";
import { Session, User, createClient } from "@supabase/supabase-js";
import { Json } from 'app/services/database.types';
import { AppState } from "app/app.state";

export namespace Auth {
  export class SetBoardingContext {
    static readonly type = '[Home Page] SetBoardingContext';
    constructor(public payload: { contextId: string }) { }
  }

  export class Login {
    static readonly type = '[Login Page] Login';
    constructor(public payload: { username: string, password: string }) { }
  }

  export class Logout {
    static readonly type = '[User Avatar] Logout';
  }

  export class SignUp {
    static readonly type = '[Sign Up Page] Sign Up';
    constructor(public payload: { username: string, password: string, code: string }) { }
  }
}

export interface AuthStateModel {
  id: string | null;
  user: User | null;
  session: Session | null;
  boardingContextId: string | null;
  boardingConfiguration: Json | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    id: null,
    session: null,
    user: null,
    boardingContextId: null,
    boardingConfiguration: null,
  }
})
@Injectable()
export class AuthState {
  @Selector()
  static username(state: AuthStateModel): string | null {
    return state.user?.email.split('@')[0];
  }

  @Selector()
  static userId(state: AuthStateModel): string | null {
    return state.user?.id;
  }

  @Selector()
  static boardingContextId(state: AuthStateModel): string | null {
    return state.boardingContextId;
  }

  @Selector()
  static boardingCustomization(state: AuthStateModel): Json | null {
    return state.boardingConfiguration;
  }

  constructor(private api: Api) { }

  @Action([Auth.SetBoardingContext])
  async setBoardingContext({ patchState, dispatch, getState, setState }: StateContext<AuthStateModel>, action: Auth.SetBoardingContext) {
    const { contextId } = action.payload;

    try {
      const { data: context, error } = await this.api.supabase
        .from('contexts')
        .select()
        .eq('id', contextId)
        .limit(1)
        .single();

      if (!context || !context.boarding_customization) {
        return;
      }

      patchState({
        boardingContextId: contextId,
        boardingConfiguration: context.boarding_customization,
      });
    } catch (e: any) {
      throw e;
    }
  }

  @Action([Auth.SignUp])
  async signUp({ patchState, dispatch, getState, setState }: StateContext<AuthStateModel>, action: Auth.SignUp) {
    const { username, password } = action.payload;

    try {
      const { data, error } = await this.api.supabase.auth.signUp({
        email: `${username}@portal.mylabel.app`,
        password: password,
        options: {
          data: {
            user_name: username
          }
        }
      });

      patchState({
        user: data.user,
        session: data.session,
        id: data.user.id,
      });
    } catch (e: any) {
      throw e;
    }
  }

  @Action([Auth.Login])
  async login({ patchState, dispatch, getState, setState }: StateContext<AuthStateModel>, action: Auth.Login) {
    const { username, password } = action.payload;

    try {
      const { data, error } = await this.api.supabase.auth.signInWithPassword({
        email: `${username}@portal.mylabel.app`,
        password: password,
      })

      patchState({
        user: data.user,
        session: data.session,
        id: data.user.id,
      });
    } catch (e: any) {
      throw e;
    }
  }

  @Action([Auth.Logout])
  async logout({ patchState, dispatch, getState, setState }: StateContext<AuthStateModel>) {
    try {
      const { error } = await this.api.supabase.auth.signOut();
      patchState({
        id: null,
        session: null,
        user: null,
      });

      dispatch(new StateClear(AuthState));
    } catch (e: any) {
      throw e;
    }
  }
}
